<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.MRC_FEES") }}</h3>
      <div>
        <base-button
            @click="reloadMrcFees()"
            type="button"
            class="elite-button btn-success"
            :disabled="loading"
        >
          <span class="btn-inner--icon mr-1">
            <i class="far fa-sync-alt"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.UPDATE_FEES") }}
          </span>
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
        </base-button>
        <base-button
            @click="openAuctionFeeModal()"
            type="button"
            class="elite-button add"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.ADD_FEE_LEVEL") }}
          </span>
        </base-button>
      </div>
    </div>

    <auction-fee-list-table
        :filterAuctionId="auction.id"
        @onOpenAuctionFeeModal="openAuctionFeeModal"
    />

    <auction-other-fees
        v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_OTHER_FEES)"
        :auction="auction"
    />

    <auction-fee-form
        :auction="auction"
        :auctionFee="auctionFee"
        :showModal="showModal"
        :loading="loading"
        :formErrors="itemsFormError"
        @onCloseAuctionFeeModal="closeAuctionFeeModal"
        @updateAuctionFeeModel="updateAuctionFeeModel"
        @onSubmitAuctionFeeForm="handleAuctionFeeFormSubmit"
        @onDeleteAuctionFee="deleteAuctionFee"
    />
  </div>
</template>
<script>
import {cloneDeep} from "lodash";
import AuctionFeeListTable from "./AuctionFeeListTable.vue";
import defaultAuctionFee from "../defaultAuctionFee";
import AuctionFeeForm from "./AuctionFeeForm.vue";
import AuctionOtherFees from "./AuctionOtherFees";

export default {
  name: "auction-view-fees",

  components: {
    AuctionFeeForm,
    AuctionOtherFees,
    AuctionFeeListTable,
  },

  props: ["auction"],

  mixins: [],

  data() {
    return {
      auctionFee: cloneDeep(defaultAuctionFee),
      loading: false,
      showModal: false,
      itemsFormError: null,
      amountMin: 0,
    };
  },

  computed: {},

  methods: {
    openAuctionFeeModal(auctionFee = null) {
      if (!auctionFee) {
        this.auctionFee = cloneDeep(defaultAuctionFee);
        this.auctionFee.amount_min = !isNaN(this.amountMin)
            ? this.amountMin
            : this.auctionFee.amount_min;
      } else {
        this.auctionFee = cloneDeep(auctionFee);
      }
      this.showModal = true;
    },

    closeAuctionFeeModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleAuctionFeeFormSubmit(auctionFeeData) {
      if (!auctionFeeData.id) {
        this.addAuctionFee(auctionFeeData);
      } else {
        this.editAuctionFee(auctionFeeData);
      }
    },

    async addAuctionFee(auctionFeeData) {
      this.loading = true;
      try {
        auctionFeeData.auction.id = this.auction.id;
        await this.$store.dispatch("auctionFees/add", auctionFeeData);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
      }
    },

    async reloadMrcFees() {
      this.loading = true;
      try {
        await this.$store.dispatch("auctions/reload", this.auction);

        this.$notify({
          type: "success",
          message: this.$t("AUCTIONS.PROPERTIES_FEE_UPDATED"),
        });

        this.loading = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "warning",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },

    async editAuctionFee(auctionFeeData) {
      this.loading = true;
      try {
        await this.$store.dispatch("auctionFees/update", auctionFeeData);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },

    async deleteAuctionFee(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("auctionFees/destroy", id);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    updateAuctionFeeModel(lastStep) {
      if (lastStep) {
        this.amountMin = parseFloat(lastStep.amount_max) + 1;
      }
    },
  },

  mounted() {
  },

  watch: {},
};
</script>
